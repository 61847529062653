<template>
  <div v-if="this.$route.params.page == 'tickets-of-orders'">
    <div class="log-header flex justify-between items-center">
      <h4 class="flex items-center">
        <feather-icon
          icon="HeadphonesIcon"
          class="mr-3"
          svgClasses="h-4 w-4"
        ></feather-icon>
        تذاكر الطرد
      </h4>
    </div>
    <vs-divider class="m-0 mb-6" />
    <table class="w-full">
      <thead>
        <tr>
          <th>الكود</th>
          <th>التفاصيل</th>
          <th>الحالة</th>
          <th>تاريخ الإنشاء</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(tr, index) in logs" :key="index">
          <td
            class="font-bold cursor-pointer"
            @click="
              $router
                .push({ path: '/apps/chat/' + tr.id, query: { type: 1 } })
                .catch(() => {})
            "
          >
            <u>#{{ tr.id }}</u>
          </td>
          <td>
            {{ tr.description }}
          </td>
          <td>{{ tr.status }}</td>
          <td>
            {{ formatDate(tr.complain.created_at) }}
          </td>
        </tr>
      </tbody>
    </table>
  </div>
  <div v-else>
    <div class="log-header flex justify-between items-center">
      <h4 class="flex items-center">
        <feather-icon
          icon="PackageIcon"
          class="mr-3"
          svgClasses="h-4 w-4"
        ></feather-icon>
        {{ $route.params.page == "order" ? $t("logOrder") : "سجل التذكرة" }}
      </h4>
      <div v-if="$route.params.page == 'order'" class="btn-log items-center">
        <button
          class="add-log"
          @click="
            $router.push(`/OrderDetails/` + $route.params.id).catch(() => {})
          "
        >
          <feather-icon
            style="width: 0.875rem; height: 0.875rem"
            class="mr-2"
            icon="EyeIcon"
          />
          {{ $t("showOrder") }}
        </button>
      </div>
    </div>
    <vs-divider class="m-0 mb-6" />
    <div class="log-body">
      <div class="flex justify-between items-center log-body-header">
        <span style="width: 50%">{{ $t("events") }}</span>
        <span style="width: 40%; text-align: center">{{
          $t("dateOfCreations")
        }}</span>
        <span style="width: 10%; text-align: center">{{ $t("creatBy") }}</span>
      </div>
      <div
        class="flex justify-between items-center log-body-body"
        v-for="(oneLog, index) in logs"
        :key="index"
      >
        <span style="width: 50%; font-size: 1rem" class="flex items-center">
          <i class="icon-log">
            <feather-icon
              icon="PackageIcon"
              style="width: 1rem; height: 1.125rem"
            ></feather-icon>
          </i>
          <span
            style="width: 100%"
            v-html="oneLog.message"
            v-if="oneLog.message"
          ></span>
          <span
            style="width: 100%"
            v-html="oneLog.description.ar"
            v-else
          ></span>
        </span>
        <span style="width: 40%; text-align: center">{{
          formatDate(oneLog.created_at)
        }}</span>
        <span style="width: 10%" class="flex items-center justify-start">
          <feather-icon
            icon="UserIcon"
            style="width: 1rem; height: 1.125rem; margin-left: 0.375rem"
          ></feather-icon>
          {{ oneLog.created_by ? oneLog.created_by : oneLog.user.full_name }}
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {
  data () {
    return {
      logs: []
    }
  },
  computed: {
    ...mapGetters('dataList', ['log']),
    ...mapGetters('ticket', ['ticket_log'])
  },
  methods: {
    ...mapActions('dataList', ['fetchLog']),
    ...mapActions('ticket', ['logTicket']),
    formatDate (date) {
      return new Date(date).toLocaleDateString('ar-EG', {
        hour: '2-digit',
        minute: '2-digit'
      })
    }
  },
  async created () {
    if (this.$route.params.page === 'order') {
      await this.fetchLog(this.$route.params.id)
      this.logs = this.log
    } else if (this.$route.params.page === 'tickets-of-orders') {
      this.logs = this.$route.query.data
    } else {
      await this.logTicket(this.$route.params.id)
      this.logs = this.ticket_log
    }
  }
}
</script>

<style lang='scss' scoped>
@import "@/assets/scss/vuexy/_variables.scss";

.log-header {
  margin-bottom: 0.9375rem;
  h4 {
    color: #212121;
    font-size: 1.125rem;
    line-height: 2.9375rem;
    font-weight: bold;
  }
  .btn-log {
    display: flex;
    .add-log {
      font-family: "cairo";
      border: none;
      background-color: $mainColor2;
      border-radius: 0.3125rem;
      width: fit-content;
      padding: 0rem 0.75rem;
      height: 2.25rem;
      display: flex;
      align-items: center;
      justify-content: center;
      color: $mainColor1;
      margin-left: 1.5rem;
      cursor: pointer;
      font-weight: 600;
    }
  }
}
.log-body {
  background-color: #ffffff;
  box-shadow: 0rem 0.25rem 0.25rem #0000000d;
  border-radius: 0.5rem;
  .log-body-header,
  .log-body-body {
    padding: 0.75rem 2.5rem;
    span {
      color: #212121;
      font-size: 0.875rem;
      line-height: 1.3125rem;
    }
    .icon-log {
      margin-right: 0.75rem;
      float: left;
      padding-top: 0.1875rem;
      width: 2rem;
      height: 2rem;
      background-color: #e9e9e9;
      border-radius: 50%;
      text-align: center;
      padding-top: 0.4375rem;
    }
  }
  .log-body-header {
    border-bottom: 0.0625rem solid rgba(0, 0, 0, 0.1);
    font-weight: 600;
  }
}

table {
  border-collapse: collapse;
  background-color: #fff;
  border-radius: 6px;
  thead {
    tr {
      th {
        text-align: left;
        border-bottom: 0.0625rem solid rgba(0, 0, 0, 0.1);
        padding: 0.75rem 2.5rem;
        &:first-child {
          border-top-left-radius: 6px;
        }
        &:last-child {
          border-top-right-radius: 6px;
        }
      }
    }
  }
  tbody {
    tr {
      td {
        text-align: left;
        padding: 0.75rem 2.5rem;
        font-size: 0.875rem;
      }
    }
  }
}
</style>
